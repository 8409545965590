/* eslint-disable react/jsx-filename-extension */
import './styles.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import likely from 'ilyabirman-likely';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { User } from '../../shapes/data';
import AuthForm from '../../components/forms/authform';

class OrderComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: '',
    };
  }

  componentDidMount() {
    likely.initiate();
  }

  renderAuthMessage = () => (
    <div>
      <p className="text">Мы его уже готовим. Приятного аппетита!</p>
    </div>
  );

  renderUnauthMessage = () => (
    <div>
      <p className="text">
        Завершите регистрацию, чтобы&nbsp;сохранять личные данные,
        <br />
        адреса заказов и&nbsp;привязывать банковские карты
      </p>
      <AuthForm formType="signup" className="sign_up-form standalone" />
    </div>
  );

  render() {
    const title = 'BARAШEK – бесплатная доставка плова и самсы в Йошкар-Оле.';
    return (
      <div className="lyt-view order_complete">
        <Helmet>
          <title>{title}</title>
          <meta content={title} name="title" />
          <meta content={title} property="og:title" />
        </Helmet>
        <div className="lyt-content">
          <h2 className="h-tac">Спасибо за заказ!</h2>
          {// Todo remove true
          this.props.user.phone
            ? this.renderAuthMessage()
            : this.renderUnauthMessage()}
          <div className="social_share">
            <p className="text">Нравится barashek? Поделитесь сервисом с друзьями! </p>
            <div className="likely likely-light">
              {/*<div className="twitter" data-url="#">*/}
              {/*  Твитнуть*/}
              {/*</div>*/}
              {/*<div className="facebook" data-url="#">*/}
              {/*  Поделиться*/}
              {/*</div>*/}
              <div className="vkontakte" data-url="#">
                Поделиться
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

OrderComplete.propTypes = {
  user: PropTypes.shape(User).isRequired,
};

OrderComplete.defaultProps = {
};

const mapStateToProps = state => ({
  user: state.rootReducer.profileReducer,
});

export default connect(mapStateToProps)(OrderComplete);
