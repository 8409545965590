import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext, useMemo } from 'react';
import Select, { createFilter } from 'react-select';
import { Context } from '../../context/providers';
import * as types from '../../shapes/data';

import { requiredFields, requiredFieldsSelf } from './shipmentUtils';

const RenderAddress = props => {
  // get settings
  const { user, settings } = useContext(Context);

  const [state, setState] = useState({
    isLoggedIn: user ? !!user.id : false,
    addressId: settings.enable_courier ? 'newAddress' : 'self',
    // addressId: 'self',
    isSaveNewAddress: false,
    newAddress: {
      address: '',
      address_city: '',
      address_other: '',
      address_street: '',
      delivery_comment: '',
      name: '',
    },
    isError: false,
    errorText: '',
    addressError: props.formErrors.addressError || false, // 'house'
    address_streetError: props.formErrors.address_streetError || false, // 'city, street'
  });

  // Update user fields on user updates
  useEffect(() => {
    const addressId = 'newAddress';
    // const addressId = 'self';
    setState({
      ...state,
      isLoggedIn: user ? !!user.id : false,
      addressId,
      // 'newAddress': (addressId !=='newAddress') ? buildNewAddressFields(addressId) : state.newAddress,
      newAddress: state.newAddress,
    });
  }, [user]);

  // update parent state with local state
  useEffect(() => {
    props.handleParentUpdate({
      addressId: state.addressId,
      isSaveNewAddress: state.isSaveNewAddress,
      newAddress: {
        address: state.newAddress.address,
        address_city: state.newAddress.address_city,
        address_other: state.newAddress.address_other,
        address_street: state.newAddress.address_street,
        delivery_comment: state.newAddress.delivery_comment,
        name: state.newAddress.name,
      },
      addressError: state.addressError,
      address_streetError: state.address_streetError,
    });
  }, [
    state.isLoggedIn,
    state.addressId,
    state.isSaveNewAddress,
    state.newAddress.address,
    state.newAddress.address_city,
    state.newAddress.address_other,
    state.newAddress.address_street,
    state.newAddress.delivery_comment,
    state.newAddress.name,
    state.addressError,
    state.address_streetError,
  ]);

  // receive form errors from parent
  useEffect(() => {
    setState({
      ...state,
      ...props.formErrors,
    });
  }, [props.formErrors]);

  const validateAddress = (stateName = '') => {
    setState({
      ...state,
      addressId: 'newAddress',
      [`${stateName}Error`]: false,
    });
    if (state.newAddress && !state.newAddress[stateName]) {
      setState({
        ...state,
        addressId: 'newAddress',
        [`${stateName}Error`]: true,
      });
    }
    return true;
  };

  const streetArray = useMemo(() => {
    if (user.streets) {
      return user.streets.map(item => (
        [...item.streets.map(streetTitle => ({
          value: `${item.city}, ${streetTitle}`,
          label: `${item.city}, ${streetTitle}`,
        }))]
      )).flat(1);
    }
    return [];
  }, [user.streets]);

  const handleNewAddressFields = (e, stateName, newValue) => {
    const { newAddress } = state;
    let isFieldRequired = false;
    if (e) {
      e.preventDefault();
      e.persist();
      const target = e.currentTarget;
      isFieldRequired = target.required || false;
      const inputNormalized = target.value.replace(/^\s{1,}/g, '');
      newAddress[stateName] = inputNormalized;
    } else {
      isFieldRequired =
        state.addressId === 'self'
          ? requiredFieldsSelf[stateName] !== 'undefined'
          : requiredFields[stateName] !== 'undefined';
      newAddress[stateName] = newValue;
    }
    setState({
      ...state,
      addressId: 'newAddress',
      newAddress,
    });
    if (isFieldRequired) {
      validateAddress(stateName);
    }
  };

  const buildNewAddressFields = id => {
    const address = user.addresses[id];
    return {
      address: address.address,
      address_other: address.address_other,
      delivery_comment: address.delivery_comment,
      address_street: {
        value: `${address.address_city}, ${address.address_street}`,
        label: `${address.address_city}, ${address.address_street}`,
      },
      name: address.name,
    };
  };

  const handleAddressChoose = (id, stateName) => {
    if (id === 'newAddress') {
      setState({
        ...state,
        addressError: false,
        address_streetError: false,
        [stateName]: id,
        newAddress: {
          address: '',
          address_city: '',
          address_other: '',
          address_street: '',
          delivery_comment: '',
          name: '',
        },
      });
    } else if (id === 'self') {
      setState({
        ...state,
        addressError: false,
        address_streetError: false,
        [stateName]: id,
        newAddress: {
          address: '',
          address_city: '',
          address_other: '',
          address_street: '',
          delivery_comment: '',
          name: '',
        },
      });
    } else {
      setState({
        ...state,
        [stateName]: id,
        newAddress: buildNewAddressFields(id),
        isSaveNewAddress: false,
        addressError: false,
        address_streetError: false,
      });
    }
  };

  const toggleField = fieldName => {
    setState({
      ...state,
      [fieldName]: !state[fieldName],
    });
  };

  const renderNewAddressControl = (title = 'Новый адрес') => (
    <label className="radio">
      <input
        name="address"
        type="radio"
        value="newAddress"
        checked={state.addressId === 'newAddress'}
        onChange={() => handleAddressChoose('newAddress', 'addressId')}
      />
      <span>{title}</span>
    </label>
  );

  const renderSavedAddresses = () => {
    const addresses = [];
    if (user.addresses) {
      user.addresses.map((item, index) => {
        addresses.push(
          <div className="form_cell col-12" key={index}>
            <label className="radio">
              <input
                name="address"
                type="radio"
                value={index}
                checked={state.addressId === index}
                onChange={() => handleAddressChoose(index, 'addressId')}
              />
              <span>
                {item.name ? item.name : 'Адрес без названия'}
                <span className="helper-text">{`${item.address_city}, ${item.address_street}`}</span>
              </span>
              <span className="is-address" />
            </label>
          </div>
        );
        return true;
      });
    }
    return addresses;
  };

  return (
    <div className="orderform_content-col orderform_content-col-address">
      <h4 className="content-subtitle">Куда доставить</h4>
      <div className="form_row">
        <div className="form_cell col-12">
          <label className="radio">
            <input
              name="address"
              type="radio"
              value="self"
              checked={state.addressId === 'self'}
              onChange={() => handleAddressChoose('self', 'addressId')}
            />
            <span>
              Забрать самостоятельно из ресторана
              <span className="helper-text">Йошкар-Ола,&nbsp;б-р Чавайна,&nbsp;23А,&nbsp;2 этаж</span>
            </span>
          </label>
        </div>
        {user && !user.id && settings.enable_courier && (
          <div className="form_cell col-12" key="newAddress">
            {renderNewAddressControl('Курьером по адресу')}
          </div>
        )}
      </div>
      {user && user.id && user.account_type !== 'operator' && (
        <fieldset className="form_fieldset">
          <div className="form_row">
            {renderSavedAddresses()}
            <div className="form_cell col-12" key="newAddress">
              {renderNewAddressControl()}
            </div>
          </div>
        </fieldset>
      )}
      {user && user.id && user.account_type === 'operator' && (
        <fieldset className="form_fieldset">
          <div className="form_row">
            <div className="form_cell col-12" key="newAddress">
              {renderNewAddressControl()}
            </div>
          </div>
        </fieldset>
      )}
      {state.addressId !== 'self' && settings.enable_courier &&
        <>
          <div className="profile-well">
            <div className="form_row">
              <div
                className={`form_cell cell-l ${state.address_streetError ? 'is-invalid' : ''}`}
              >
                <label>
                  <span className="label-text">Улица</span>
                  {user && (
                    <Select
                      className="react-select-container"
                      classNamePrefix="select"
                      name="select-city"
                      required={state.addressId !== 'self' ? 'required' : false}
                      placeholder="Город, улица"
                      value={state.newAddress.address_street}
                      onChange={newValue =>
                        handleNewAddressFields('', 'address_street', newValue)
                      }
                      options={streetArray}
                      filterOption={createFilter({ ignoreAccents: false })}
                      noOptionsMessage={() => 'адрес не найден'}
                      isSearchable
                      isClearable
                    />
                  )}
                  <span className="is-invalid-text">
                    Укажите адрес доставки
                  </span>
                </label>
              </div>
              <div
                className={`form_cell cell-s ${
                  state.addressError ? 'is-invalid' : ''
                }`}
              >
                <label>
                  <span className="label-text">Дом</span>
                  <input
                    value={state.newAddress.address}
                    required={state.addressId !== 'self' ? 'required' : false}
                    name="addressHouse"
                    type="text"
                    onChange={e => handleNewAddressFields(e, 'address')}
                    maxLength="10"
                  />
                  <span className="is-invalid-text">Укажите номер дома</span>
                </label>
              </div>
              <div className="form_cell cell-s">
                <label>
                  <span className="label-text">Квартира</span>
                  <input
                    value={state.newAddress.address_other}
                    name="addressApartment"
                    type="text"
                    onChange={e => handleNewAddressFields(e, 'address_other')}
                    maxLength="5"
                  />
                </label>
              </div>
            </div>
            <div className="form_row">
              <div className="form_cell cell-xl">
                <label>
                  <span className="label-text">Комментарий (необязательно)</span>
                  <input
                    value={state.newAddress.delivery_comment}
                    name="addressComment"
                    placeholder="Код домофона, как лучше проехать"
                    type="text"
                    onChange={e =>
                      handleNewAddressFields(e, 'delivery_comment')
                    }
                  />
                </label>
              </div>
            </div>
          </div>
          {state.isLoggedIn &&
            user.account_type !== 'operator' &&
            state.addressId === 'newAddress' && (
              <div className="form_row">
                <div className="form_cell col-12">
                  <label className="checkbox">
                    <input
                      name="new_address_save"
                      type="checkbox"
                      value="1"
                      onChange={() => toggleField('isSaveNewAddress')}
                      checked={state.isSaveNewAddress}
                    />
                    <span>Добавить адрес в мою учетную запись</span>
                  </label>
                </div>
                {state.isSaveNewAddress && (
                  <div className="form_row">
                    <div className="form_cell cell-l">
                      <label>
                        <span className="label-text">Название адреса (необязательно)</span>
                        <input
                          id="customAddressName"
                          name="customAddressName"
                          type="text"
                          defaultValue={state.newAddress.name}
                          onChange={e => handleNewAddressFields(e, 'name')}
                        />
                      </label>
                    </div>
                  </div>
                )}
              </div>
            )}
        </>
      }
    </div>
  );
};

RenderAddress.propTypes = {
  handleParentUpdate: PropTypes.func,
  user: PropTypes.shape(types.User),
  formErrors: PropTypes.shape({}),
};

RenderAddress.defaultProps = {
  user: {},
  formErrors: {},
};

export default RenderAddress;
