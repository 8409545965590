import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import 'moment/locale/ru';
import MaskedInput, { conformToMask } from 'react-text-mask';
import { Context } from '../../context/providers';
import { patterns, masks } from '../../constants/input';
import * as types from '../../shapes/data';

const RenderContacts = props => {
  // get user
  const { user } = useContext(Context);

  // state
  const [state, setState] = useState({
    isLoggedIn: user ? !!user.id : false,
    phone: user ? user.phone : '',
    name: user ? user.name : '',
    isError: false,
    nameError: false,
    phoneError: false,
  });

  // Update user fields on user updates
  useEffect(() => {
    let maskedPhoneNumber = {};
    if (user && user.phone) {
      maskedPhoneNumber = conformToMask(user.phone, masks.phone, {
        guide: false,
      });
    }
    setState({
      ...state,
      // isLoggedIn: user ? !!user.id : false,
      phone: maskedPhoneNumber.conformedValue,
      name: user ? user.name : '',
      nameError: !user,
      phoneError: !user,
    });
  }, [user]);

  // receive form errors from parent
  useEffect(() => {
    setState({
      ...state,
      ...props.formErrors,
    });
  }, [props.formErrors]);

  // update parent state with local state
  useEffect(() => {
    props.handleParentUpdate({
      isLoggedIn: state.isLoggedIn,
      name: state.name,
      phone: state.phone,
      nameError: state.nameError,
      phoneError: state.phoneError,
    });
  }, [
    state.name,
    state.phone,
    state.nameError,
    state.phoneError,
  ]);

  const validateField = (val, stateName, e) => {
    if (val && patterns[stateName]) {
      if (val && !patterns[stateName].test(val)) {
        setState({
          ...state,
          [`${stateName}Error`]: true,
        });
      } else {
        setState({
          ...state,
          [`${stateName}Error`]: false,
        });
      }
    } else if (!val) {
      setState({
        ...state,
        [`${stateName}Error`]: true,
      });
    } else {
      if (
        e &&
        e.target.dataset.max &&
        parseInt(val) >= parseInt(e.target.dataset.max)
      ) {
        val = e.target.dataset.max;
      }
      setState({
        ...state,
        [stateName]: val,
        [`${stateName}Error`]: false,
      });
    }
    return true;
  };

  const handleFieldChange = (e, stateName) => {
    e.preventDefault();
    e.persist();

    const { target } = e;
    const inputNormalized = target.value.replace(/^\s{1,}/g, '');

    setState({
      ...state,
      [`${stateName}Error`]: false,
      [stateName]: inputNormalized,
    });
  };

  const nameMasked = state.name || '';
  const phoneMasked = state.phone || '';
  const inputProps = {
    placeholder: '+7 (___) ___-__-__',
    type: 'tel',
    name: 'userPhone',
    autoComplete: 'off',
  };
  console.log('phone', user.phone)

  return (
    <div className="orderform_content-col orderform_content-col-contacts">
      <h4 className="content-subtitle">Контактные данные</h4>
      <div className="form_row">
        <div
          className={`form_cell cell-l ${state.nameError ? 'is-invalid' : ''}`}
        >
          <label>
            <span className="label-text">Имя</span>
            <input
              value={nameMasked}
              name="name"
              placeholder="Имя"
              required="required"
              type="text"
              onChange={e => handleFieldChange(e, 'name')}
              onBlur={e => validateField(e.target.value, 'name')}
            />
            <span className="is-invalid-text">Введите Ваше имя</span>
          </label>
        </div>
        <div
          className={`form_cell cell-m ${state.phoneError ? 'is-invalid' : ''}`}
        >
          <label>
            <span className="label-text">Tелефон</span>
            <MaskedInput
              {...inputProps}
              value={phoneMasked}
              required="required"
              mask={masks.phone}
              className="form-control"
              guide={false}
              readOnly={!!user.phone}
              onChange={e => handleFieldChange(e, 'phone')}
              onBlur={e => validateField(e.target.value, 'phone')}
            />
            <span className="helper-text">Для подтверждения заказа</span>
            <span className="is-invalid-text">Укажите номер телефона</span>
          </label>
        </div>
      </div>
    </div>
  );
};

RenderContacts.propTypes = {
  user: PropTypes.shape(types.User),
  formErrors: PropTypes.shape({}),
};

RenderContacts.defaultProps = {
  user: {},
  formErrors: {},
};

export default RenderContacts;
