/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-filename-extension */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import ScrollToTop from '../../utils/scrollToTop';
import { getCookie, setCookie } from '../../utils/cookies';
import anchorClick from '../../utils/anchorClick';
import Header from '../header';
import Footer from '../footer';
import CookieAgreement from '../cookieAgreement';
import About from '../about';
import * as types from '../../shapes/data';
import { Provider } from '../../context/providers';

const RouteLayout = ({
  user,
  basket,
  settings,
  products,
  children,
  currentUser,
}) => {
  const appVer = getCookie('barashekver');

  const [state, setState] = useState({
    user: {},
    isLoading: true,
    version: appVer || 0,
  });

  const refreshCacheAndReload = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(function (names) {
        for (const name of names) caches.delete(name);
      });
    }
    // delete browser cache and hard reload
    window.location.reload(true);
  };

  useEffect(() => {
    currentUser();
    setState({
      ...state,
      isLoading: true,
    });
    // handle links and hash-links
    anchorClick();
  }, []);

  useEffect(() => {
    setState({
      ...state,
      user,
      settings,
      basket,
      products,
    });

    if (state.user && state.basket && state.settings && state.products) {
      setState({
        ...state,
        isLoading: false,
      });

      if (settings && settings.version) {
        const currentVersion = parseInt(state.version);
        const latestVersion = parseInt(settings.version);
        const shouldForceRefresh = latestVersion > currentVersion;
        if (shouldForceRefresh) {
          // update version cookie
          setCookie('barashekver', latestVersion, 1);
          setState({
            ...state,
            version: latestVersion,
            isLoading: false,
          });
          refreshCacheAndReload();
        } else {
          setState({
            ...state,
            isLoading: false,
          });
        }
      }
    }
  }, [user, basket, settings, products]);

  return (
    <Provider
      value={{
        user,
        settings,
        basket,
        products,
      }}
    >
      <ScrollToTop>
        <Helmet>
          <meta charSet="utf-8" />
          <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        </Helmet>
        <>
          <Header />
          <main className="lyt-main" id="main">
            {children}
          </main>
          <About />
        </>
        <Footer />
        <CookieAgreement />
      </ScrollToTop>
    </Provider>
  );
};

const mapStateToProps = state => ({
  user: state.rootReducer.profileReducer,
  basket: state.rootReducer.basketReducer.basket,
  settings: state.rootReducer.settingsReducer.settings,
  products: state.rootReducer.productsReducer.products,
});

const mapDispatchToProps = dispatch => ({
  currentUser: () => {
    dispatch({ type: 'CALL_CURRENT_USER' });
  },
});

RouteLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.node]).isRequired,
  user: PropTypes.shape(types.User),
  basket: PropTypes.shape(types.Basket),
  settings: PropTypes.shape(types.Settings),
  products: PropTypes.array,
  currentUser: PropTypes.func.isRequired,
};

RouteLayout.defaultProps = {
  user: {},
  basket: {},
  settings: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(RouteLayout);
