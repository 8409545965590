/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react';
import { getCookie, setCookie } from '../../utils/cookies';
import './styles.css';

const CookieAgreement = () => {

  const [state, setState] = useState({
    agreementValue: getCookie('cookieAgreement')
  });

  const confirmAgreement = () => {
    setCookie('cookieAgreement', 'true', 365);
    setState({agreementValue: 'true'})
  }

  if (state.agreementValue) {
    return null;
  }

  return (
    <div className="popup" id="cookie-agreement">
      <div className="popup-inner">
        <div className="popup-message">
          В целях предоставления качественных услуг сервис ведет учет и обработку Cookie, IP-адресов, а также Яндекс.Метрику и других данных необходимых для взаимодействия вашего браузера с Сервисом. Оставаясь на сайте, вы соглашаетесь на применение указанных технологий и обработку данных в соответствии с <a target="_blank" href="/agreements">Политикой конфиденциальности</a>
        </div>
        <button type="button" className="btn btn-submit" onClick={confirmAgreement}>Согласен</button>
      </div>
    </div>
  );
};

export default CookieAgreement;
