/* eslint-disable camelcase */
import React, { useContext } from 'react';
import { Timetable } from '../timetable';
import { Context } from '../../context/providers';
import './styles.css';

const About = () => {
  const { settings } = useContext(Context);

  return (
    <div className="container cards-about" id="about-card-anchor">
      <div className="card-group">
        <div className="card">
          <div className="card-item is-about">
            <div className="card-item_media">
              <img
                alt=""
                className="card-item_media-img"
                src="/i/layout/salver.png"
              />
            </div>
            <div className="card-item_content">
              <div className="card-item_content-title">Доставка</div>
              <div className="card-item_content-text">
                Доставка&nbsp;&mdash;&nbsp;
                {settings && settings.shipment_cost && `${settings.shipment_cost}`}
                &nbsp;рублей
                {settings && settings.shipment_threshold && (
                    <>
                      <br />
                      При заказе от&nbsp;{settings.shipment_threshold}&nbsp;рублей&nbsp;&mdash; бесплатно!
                    </>
                )}
                <br />
                Среднее время доставки&nbsp;&mdash; 60&nbsp;минут
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-item is-about" id="delivery_timetable">
            <div className="card-item_media">
              <img
                alt=""
                className="card-item_media-img"
                src="/i/layout/clock.png"
              />
            </div>
            <div className="card-item_content">
              <div className="card-item_content-title">Прием заказов</div>
              <div className="card-item_content-text">
                <Timetable today={false} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
