import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Context } from '../../context/providers';
import { useInterval } from '../../hooks/useInterval';
// import * as types from '../../shapes/data';

const deliveryTimeStep = 30;

const RenderTime = ({ fetchTime, sendNotification, handleParentUpdate }) => {
  const { settings } = useContext(Context);

  // RenderTime state
  const [state, setState] = useState({
    shipTimeId: 0,
    isSending: false,
    showTimePicker: false,
    deliveryTime: '',
    isNoop: false,
    serverTimeStamp: '',
    time: {},
    deliveryMinTime: '',
    startTime: {},
    stopTime: {},
  });

  const [delay, setDelay] = useState(30000);
  const [isRunning, setIsRunning] = useState(true);

  useInterval(
    () => {
      fetchTime();
    },
    isRunning ? delay : null
  );

  const padMin = value => (value < 10 ? `0${value}` : value);

  const setTimes = () => {
    if (settings && settings.timetable && state.serverTimeStamp) {
      // set current time moment
      const time = moment.utc(state.serverTimeStamp, 'YYYY-mm-DDTHH:mm:ss.SSS');

      // current day
      const currentDate = new Date();
      const currentDay = currentDate.getDay();
      let day;
      if (currentDay === 0) {
        day = 6;
      } else if (currentDay > 0) {
        day = currentDay - 1;
      }

      // current time HH:MM
      const curHH = `${padMin(time.hours())}`;
      const curMM = `${padMin(time.minutes())}`;
      const timeHHMM = `${curHH}:${curMM}`;

      // delivery time based on day
      const timeDelivery = settings.timetable[day].match(/([0-2]\d+:[0-5]\d+)\s-\s([0-2]\d+:[0-5]\d+)/s);

      // start hours & minutes
      const timeStart = timeDelivery[1];
      const timeStartHH = timeStart.match(/([0-2]\d+):[0-5]\d+/)[1];
      const timeStartMM = timeStart.match(/[0-2]\d+:([0-5]\d+)/)[1];
      // const timeStartMM = 0;

      // stop hours & minutes
      const timeStop = timeDelivery[2];
      const timeStopHH = timeStop.match(/([0-2]\d+):[0-5]\d+/)[1];
      const timeStopMM = timeStop.match(/[0-2]\d+:([0-5]\d+)/)[1];

      // set start time moment
      const startTime = moment(time).hours(timeStartHH).minutes(timeStartMM);

      const startTimeHHMM = `${timeStartHH}:${timeStartMM}`;

      // set stop time moment
      const stopTime = moment(time).hours(timeStopHH).minutes(timeStopMM);

      const stopTimeHHMM = `${timeStopHH}:${timeStopMM}`;

      // round current delivery time + delivery interval
      const delta = 15 + parseInt(curMM);
      const ratio = Math.ceil(delta / deliveryTimeStep);
      let minsRoundUp;
      switch (ratio) {
        case 1:
          minsRoundUp = deliveryTimeStep * 2;
          break;
        case 2:
          minsRoundUp = deliveryTimeStep * 3;
          break;
        default:
          minsRoundUp = deliveryTimeStep * 4;
      }

      const timeRoundUp = time.minute(minsRoundUp);

      let deliveryMinTime;

      if (timeRoundUp > startTime && timeRoundUp < stopTime) {
        deliveryMinTime = timeRoundUp.format('LT');
      } else if (timeRoundUp <= startTime) {
        deliveryMinTime = startTime.format('LT');
      } else {
        deliveryMinTime = stopTime.format('LT');
      }

      const newTimeState = {
        time,
        timeHHMM,
        startTime,
        startTimeHHMM,
        stopTime,
        stopTimeHHMM,
        deliveryMinTime,
      };

      setState({
        ...state,
        ...newTimeState,
      });
    }
  };

  // calculate delivery start/stop time on settings load
  useEffect(() => {
    if (settings && settings.current_time) {
      const serverTimeStamp = settings.current_time;
      setState({
        ...state,
        serverTimeStamp,
      });
    }
  }, [settings]);

  useEffect(() => {
    if (settings && settings.timetable && state.serverTimeStamp) {
      setTimes();
    }
  }, [state.serverTimeStamp]);

  // clear delivery time if shipTimeId === 0
  useEffect(() => {
    if (state.shipTimeId === 0) {
      setState({
        ...state,
        deliveryTime: '',
      });
    }
    if (state.shipTimeId === 1) {
      setState({
        ...state,
        deliveryTime: state.deliveryMinTime,
      });
    }
  }, [state.shipTimeId]);

  // set noop message
  useEffect(() => {
    if (state.timeHHMM && state.startTimeHHMM && state.stopTimeHHMM) {
      const t = state.timeHHMM;
      const tStart = state.startTimeHHMM;
      const tStop = state.stopTimeHHMM;

      if (t >= tStop || t < tStart) {
        sendNotification({
          data: {
            message_data: `Внимание: заказы принимаются с ${tStart} до ${tStop}`,
            message_type: 'notification',
            message_id: 'noop_ship_error',
          },
        });
        setState({
          ...state,
          isNoop: true,
        });
      } else {
        setState({
          ...state,
          isNoop: false,
        });
      }
    }
  }, [state.isSending, state.time]);

  // update parent state with local state
  useEffect(() => {
    handleParentUpdate(state);
  }, [state.isSending, state.isNoop, state.shipTimeId, state.deliveryTime]);

  const handleRadioChoose = (id, stateName) => {
    setState({
      ...state,
      [stateName]: id,
    });
  };

  const handleTimeChange = (timeObj) => {
    const isMoment = moment.isMoment(timeObj);
    let HHMM;
    if (isMoment) {
      HHMM = timeObj.format('LT');
    } else {
      const d = new Date(timeObj);
      HHMM = `${padMin(d.getHours())}:${padMin(d.getMinutes())}`;
    }
    setState({
      ...state,
      deliveryTime: HHMM,
      showTimePicker: false,
    });
  };

  const toggleTimePicker = () => {
    setState({
      ...state,
      showTimePicker: !state.showTimePicker,
    });
  };

  const { deliveryTime, deliveryMinTime, stopTimeHHMM } = state;

  let minTime;
  let stopTime;

  if (deliveryMinTime && stopTimeHHMM) {
    // min/stop time as Moment objects
    // minTime = moment(state.deliveryMinTime, 'hh:mm');
    // stopTime = moment(state.stopTimeHHMM, 'hh:mm');

    // min/stop time as Date objects
    const minHHMM = deliveryMinTime.split(':');
    const maxHHMM = stopTimeHHMM.split(':');
    const date = new Date();
    minTime = date.setHours(minHHMM[0], minHHMM[1]);
    stopTime = date.setHours(maxHHMM[0], maxHHMM[1]);
  }

  if  (!settings.enable_courier) {
    return null
  }

  return (
    <div className="orderform_content-col orderform_content-col-time">
      <h4 className="content-subtitle">Когда доставить</h4>
      <div className="form_row">
        <div className="form_cell col-12">
          <label className="radio">
            <input
              name="shipTime"
              type="radio"
              value="0"
              checked={state.shipTimeId === 0}
              onChange={() => handleRadioChoose(0, 'shipTimeId')}
            />
            <span>Как можно скорее</span>
          </label>
          <span className="is-invalid-text">Ошибка времени доставки</span>
        </div>
        <div className="form_cell col-12">
          <label className="radio">
            <input
              name="shipTime"
              type="radio"
              value="1"
              checked={state.shipTimeId === 1}
              onChange={() => handleRadioChoose(1, 'shipTimeId')}
              disabled={state.isNoop}
            />
            <span>В назначенное время</span>
          </label>
          {state.shipTimeId === 1 && minTime && stopTime && (
            <div className="select-time shipment-time">
              <div className="timelist__titleitem" onClick={toggleTimePicker}>
                {deliveryTime || deliveryMinTime}
              </div>
              <div className="timelist" />
              {state.showTimePicker && (
                <DatePicker
                  showTimeSelect
                  inline
                  timeFormat="HH:mm"
                  timeIntervals={deliveryTimeStep}
                  minTime={minTime}
                  maxTime={stopTime}
                  dateFormat="LLL"
                  onChange={handleTimeChange}
                />
              )}
            </div>
          )}

          <span className="is-invalid-text">Ошибка времени доставки</span>
        </div>
      </div>
    </div>
  );
};

RenderTime.propTypes = {
  handleParentUpdate: PropTypes.func,
  sendNotification: PropTypes.func,
  fetchTime: PropTypes.func,
  // clearNotification: PropTypes.func,
  // isSending: PropTypes.func,
  // settings: PropTypes.shape(types.Settings),
};

RenderTime.defaultProps = {
  // settings: {},
};

export default RenderTime;
