import PropTypes from 'prop-types';
import React, { useState } from 'react';
import * as types from '../../shapes/data';

const RenderBuyInfo = ({
  withSubmit = false,
  handleSubmitData,
  isSending,
  errorText,
  promo,
  bonus,
  settings,
  basket,
  isNoop,
  addressId,
}) => {
  const [agreements, setAgreements] = useState(false);

  const toggleAgreements = () => {
    setAgreements((state) => !state);
  };

  let sum =
    promo && !promo.promo_code_invalid && promo.discount_size
      ? basket.sum - promo.discount_size
      : basket.sum;
  if (sum < 0) {
    sum = 0;
  }

  let shipment_cost = settings.shipment_cost
  if (settings.shipment_threshold) {
    let diff = settings.shipment_threshold - sum;
    if ((promo && promo.free_shipment) || addressId === 'self') {
      diff = 0;
    }

    shipment_cost = diff > 0 ? settings.shipment_cost : 0
  }
  if (addressId === 'self') {
    shipment_cost = 0;
  }
  // let diff = settings.shipment_threshold - sum;
  // if ((promo && promo.free_shipment) || addressId === 'self') {
  //   diff = 0;
  // }
  // sum = diff > 0 ? Math.floor(sum + settings.shipment_cost) : Math.floor(sum);
  sum = Math.floor(sum + shipment_cost)
  const isError = promo ? promo.promo_code_invalid : false;
  sum = bonus && bonus > 0 ? sum - bonus : sum;
  if (sum < 0) {
    sum = 0;
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div
            className={`orderlist_total ${withSubmit ? 'before_submit' : ''}`}
          >
            <span className="sum_row">
              Сумма заказа:
              <span className="lyt-panel-sum">
                {basket.sum}
                <span className="rub">руб.</span>
              </span>
            </span>
            {shipment_cost > 0 && (
              <span className="sum_row">
                Доставка:
                <span className="lyt-panel-sum">
                  {shipment_cost}
                  <span className="rub">руб.</span>
                </span>
              </span>
            )}
            {promo && !promo.promo_code_invalid && promo.discount_size > 0 && (
              <span className="sum_row promo-sum">
                Скидка:
                <span className="lyt-panel-sum">
                  {Math.floor(promo.discount_size)}
                  <span className="rub">руб.</span>
                </span>
              </span>
            )}
            {bonus && (
              <span className="sum_row promo-sum">
                Бонусами:
                <span className="lyt-panel-sum">
                  {Math.floor(bonus)}
                  <span className="rub">руб.</span>
                </span>
              </span>
            )}
            <span className="sum_row total_row">
              К оплате:
              <span className="lyt-panel-sum">
                {/* {diff > 0 ? Math.floor(sum+settings.shipment_cost) : Math.floor(sum)} */}
                {sum}
                <span className="rub">руб.</span>
              </span>
            </span>
          </div>
          {withSubmit && (
            <label className="checkbox">
              <input
                id="agreements"
                type="checkbox"
                checked={agreements}
                onChange={toggleAgreements}
              />
              <span className='text-left'>Принимаю <a href="#" onClick={() => {window.open('/terms')}}>условия сервиса</a>. С <a href="#" onClick={() => {window.open('/agreements')}}>политикой конфиденциальности</a> и <a href="#" onClick={() => {window.open('/pd.pdf')}}>Положением об обработке персональных данных</a> ознакомлен.</span>
            </label>
          )}
        </div>
      </div>
      {withSubmit && (
        <div className="row">
          <div className="col-auto">
            <button
              className="btn btn-submit"
              type="submit"
              onClick={(e) => {
                handleSubmitData(e);
              }}
              disabled={isSending || isNoop || !agreements}
            >
              Оформить заказ
            </button>
          </div>
          {errorText && (
            <div className="col-auto helper-wrap">
              <div className="helper-text">{errorText}</div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

RenderBuyInfo.propTypes = {
  isNoop: PropTypes.bool,
  withSubmit: PropTypes.bool,
  errorText: PropTypes.string,
  isSending: PropTypes.func,
  handleSubmitData: PropTypes.func,
  basket: PropTypes.shape(types.Basket),
  settings: PropTypes.shape(types.Settings),
  promo: PropTypes.shape({}),
  bonus: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  addressId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

RenderBuyInfo.defaultProps = {
  basket: {},
  settings: {},
};

export default RenderBuyInfo;
