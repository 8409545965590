/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react';
import './styles.css';

const ProductCard = ({ product, basket, addToBasket, removeFromBasket }) => {
  const [portionId, setPortionId] = useState(product.active)
  const [isCardDetailsShow, setDetailsShow] = useState(false)


  const handleRadioClick = (e, id) => {
    e.preventDefault();
    setPortionId(id)
  };

  const handleAddToBasket = (e, product) => {
    e.preventDefault();
    addToBasket(product);
  };

  const handleRemoveFromBasket = (e, product) => {
    e.preventDefault();
    removeFromBasket(product);
  };

  const toggleCardDetails = () => {
    setDetailsShow(!isCardDetailsShow)
  };

  const formatDetailsValue = value => {
    return parseFloat(value).toFixed(1)
  }

  const renderCardDetails = (product) => (
    <div className="card-item_content-text card-item_details">
      <span
        className={`card-item_details-popup ${
            isCardDetailsShow ? '' : 'hidden'
        }`}
      >
        <span
          className="card-item_details-popup_close"
          onClick={toggleCardDetails}
        >
          &times;
        </span>
        <span className="card-item_details-popup_text"><span>Пищевая ценность</span><span>{formatDetailsValue(product.calories)} ккал</span></span>
        <span className="card-item_details-popup_text"><span>Белки</span><span>{formatDetailsValue(product.fat)} г</span></span>
        <span className="card-item_details-popup_text"><span>Жиры</span><span>{formatDetailsValue(product.proteins)} г</span></span>
        <span className="card-item_details-popup_text"><span>Углеводы</span><span>{formatDetailsValue(product.carbohydrates)} г</span></span>
      </span>

      <span
        className="card-item_details-toggler"
        onClick={toggleCardDetails}
      >
        Пищевая ценность на&nbsp;100&nbsp;г.
      </span>
    </div>
  );

  const renderPortions = (subCategory, isActive) => (
    <div className="card-item_content-text">
      <span className="radiogroup">
        {subCategory.products.map(portionData => (
          <label key={portionData.id}>
            <input
              disabled={`${!isActive ? 'disabled' : ''}`}
              className={`radiogroup-input${
                portionData.id === portionId ? ' checked' : ''
              }`}
              type="radio"
              name={`portion${subCategory.name}`}
              onChange={e => handleRadioClick(e, portionData.id)}
            />
            <span>{portionData.portion_name}</span>
          </label>
        ))}
      </span>
    </div>
  );

  const renderCard = (product, subCategory, basket) =>
    !product.invisible && (
      <div
        key={product.id}
        className={`card-item${
          subCategory.products.length > 1 && subCategory.name
            ? ' portioned'
            : ''
        }${
          subCategory.products.length > 1 && portionId === product.id
            ? ' portioned-active'
            : ''
        }${product.active ? '' : ' is-inactive'}
        `}
      >
        <div
          className={`card-item_media has-sticker-${product.sticker}${
            product.active ? '' : ' inactive'
          }`}
        >
          {!product.active && (
            <span className="card-inactive-message">
              Сегодня, к&nbsp;сожалению, {product.message_absent}
            </span>
          )}
          <img
            alt={product.title}
            className="card-item_media-img"
            src={product.img}
            height="260"
            width="260"
          />
        </div>
        <div className="card-item_content">
          <div className="card-item_content-title">{product.title}</div>
          {product.text && (
            <div className="card-item_content-text">
              <span dangerouslySetInnerHTML={{ __html: product.text }} />
              <span className="card-item_content-comment">&nbsp;{product.weight}&nbsp;{product.category === 'Напитки' ? 'мл' : 'г'}</span>
            </div>
          )}
          {product.calories &&
            renderCardDetails(product)}
          {subCategory.products.length > 1 &&
            subCategory.name &&
            renderPortions(subCategory, product.active)}

          <div className="card-item_content-controls">
            <span className="card-item_control-price">
              {product.price}
              <span className="rub">руб.</span>
            </span>

            {product.active && (
              <div className="card-item_control-amount">
                {basket.products && basket.products.has(product.id) && (
                  <>
                    <div className="card-item_inbasket-status">В корзине</div>
                    <label className="card-item_control-amount-input">
                      <span
                        className="card-item_control-amount-button-m"
                        onClick={e => handleRemoveFromBasket(e, product)}
                      >
                        &minus;
                      </span>
                      <input
                        name={product.id}
                        readOnly="readonly"
                        type="text"
                        value={basket.products.get(product.id).amount}
                      />
                      <span
                        className="card-item_control-amount-button-p"
                        onClick={e => handleAddToBasket(e, product)}
                      >
                        +
                      </span>
                    </label>
                  </>
                )}
                <button
                  type="button"
                  className={`card-item_control-submit btn is-small${
                    basket.products &&
                    basket.products.has(product.id) &&
                    basket.products.get(product.id).amount > 0
                      ? ' invisible'
                      : ''
                  }`}
                  onClick={e => handleAddToBasket(e, product)}
                >
                  В корзину
                </button>
              </div>
            )}

            {!product.active && (
              <div className="card-item_control-amount">
                {basket.products && basket.products.has(product.id) && (
                  <div className="card-item_inbasket-status">В корзине</div>
                )}
                <button
                  disabled
                  type="button"
                  className="card-item_control-submit btn is-small"
                >
                  Разобрали
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );

  return (
    !product.invisible && (
      <div className="card">
        <div className="card-wrap">
          {product.products.map(item => renderCard(item, product, basket))}
        </div>
      </div>
    )
  );

  // const productsArray = useMemo(() => {
  //   return product.products.map(item => renderCard(item, product, basket))
  // }, [product, basket, portionId]);
};

ProductCard.defaultProps = {
  subCategory: {
    name: 'Чайханский',
    active: 1,
    products: [
      {
        id: 1,
        img: '1.png',
        sticker: 'garlic',
        title: 'Плов Чайханский',
        text:
          'Приготовленный в казане, с чесноком, барбарисом и ароматной зирой, 230 г.',
        price: 195,
        active: true,
        message_absent: 'плов закончился',
        show_in_slider: false,
        portion_name: 'Стандартная',
        default_active_portion: true,
        portion_order: 0,
        promo_text: null,
        slider_order: 3,
        sliderImg: '1.jpg',
        contents: null,
        calories: null,
        invisible: false,
        fat: null,
        proteins: null,
        carbohydrates: null,
      },
      {
        id: 8,
        img: '1.png',
        sticker: 'garlic',
        title: 'Плов Чайханский большой',
        text:
          'Приготовленный в казане, с чесноком, барбарисом и ароматной зирой, 350 г.',
        price: 275,
        active: true,
        message_absent: 'плов закончился',
        show_in_slider: false,
        portion_name: 'Большая',
        default_active_portion: false,
        portion_order: 1,
        promo_text: null,
        slider_order: 0,
        sliderImg: '1.jpg',
        contents: null,
        calories: null,
        invisible: false,
        fat: null,
        proteins: null,
        carbohydrates: null,
      },
    ],
  },
  basket: {
    sum: 0,
    amount: 0,
    products: new Map(),
  },
};

export default ProductCard;
