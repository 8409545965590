import React from 'react';
import { Helmet } from 'react-helmet';

const Agreements = () => {
  const title = 'Политика конфиденциальности';

  return (
    <div className="container">
      <Helmet>
        <title>{`${title} – BARAШEK`}</title>
        <meta content={`${title} – BARAШEK`} name="title" />
        <meta content={title} property="og:title" />
      </Helmet>
      <h1 className="h-tac">{title}</h1>
      <div className="content-index">
        <section className="content-section">
          <em>Термины и определения</em>
          <div className="box">
            <p>
              <strong>Общество</strong> – Общество с ограниченной ответственностью «Вкусные технологии»,
              ИНН 1215210507, находящееся по адресу: 424038, Республика Марий Эл, г. Йошкар-Ола, ул. Кирова, д. 3А, оф. 101.
            </p>
            <p>
              <strong>Персональные данные</strong> – любая информация, относящаяся к прямо или косвенно определённому или определяемому физическому лицу (субъекту персональных данных).
            </p>
            <p>
              <strong>Пользователь</strong> - физическое лицо, использующее Сервисы и функционал сайта, в том числе посетители сайта.
            </p>
            <p>
              <strong>Сервисы</strong> - сайт Общества в сети Интернет (barashek.ru), системы, услуги, предоставляемые Обществом.
            </p>
          </div>
        </section>
        <section className="content-section">
          <em>Общие положения</em>
          <div className="box">
            <p>
              Настоящая Политика конфиденциальности Общества (далее по тексту – Политика) действует в отношении информации, которую Общество может получить в связи
              с использованием Пользователем Сервисов, в том числе персональных данных Пользователя и иных пользовательских данных (далее по тексту – Информация).
            </p>
            <p>
              Настоящая Политика применима только к Сервисам. Общество не контролирует и не несет ответственность за сервисы третьих лиц, на которые Пользователь может перейти по ссылкам, доступным из Сервисов, в том числе за обрабатываемую третьими лицами Информацию о Пользователе.
            </p>
            <p>
              При использовании Сервисов Пользователь соглашается с условиями настоящей Политики. В случае несогласия с этими условиями Пользователь должен воздержаться
              от использования Сервисов.
            </p>
          </div>
        </section>
        <section className="content-section">
          <em>Информация о Пользователе</em>
          <div className="box">
            <p>
              Состав Информации о Пользователе:
              <ul>
                <li>
                  данные, которые Пользователь предоставляет Обществу самостоятельно или через третьих лиц при регистрации и в процессе использования Сервисов;
                </li>
                <li>
                  данные, которые могут быть получены Обществом в результате действий Пользователя при использовании Сервисов.
                </li>
              </ul>
            </p>
            <p>
              При необходимом и достаточном объеме Информации о Пользователе
              в соответствии с действующим законодательством Российской Федерации, в том числе Федеральным законом от 27 июля 2011 г. № 152-ФЗ «О персональных данных» (далее
              по тексту – Федеральный закон «О персональных данных»), данная информация может быть отнесена к персональным данным. В таком случае Информация о Пользователе обрабатывается в Обществе как персональные данные на условиях настоящей Политики, Положения об обработке персональных данных ООО «Вкусные технологии» и внутренних нормативных документов Общества по вопросам персональных данных.
            </p>
            <p>
              Общество исходит из того, что Пользователь предоставляет достоверную и достаточную информацию и поддерживает эту информацию в актуальном состоянии. Последствия предоставления недостоверной или недостаточной информации определены
              в соглашениях, заключаемых между Обществом и Пользователем.
            </p>
            <p>
              Информация о Пользователе, которую обрабатывает Общество, представлена
              в Приложении № 1. В случае, если Пользователь в процессе использования Сервисов предоставляет данные третьих лиц, Пользователь гарантирует Обществу наличие согласия третьего лица на обработку Обществом данных в соответствии с условиями данной Политики.
            </p>
          </div>
        </section>
        <section className="content-section">
          <em>Условия обработки Информации</em>
          <div className="box">
            <p>
              4.1. Принципы обработки информации:
            </p>
            <p>
              Обработка информации в Обществе осуществляется на следующих принципах:
              <ul>
                <li>
                  обработка информации на законной и справедливой основе;
                </li>
                <li>
                  обработка информации ограничивается заранее определенными и законными целями, не допускается обработка информации, несовместимая с целями сбора информации;
                </li>
                <li>
                  не допускается объединение баз данных, содержащих информацию, обработка которых осуществляется в целях, несовместимых между собой;
                </li>
                <li>
                  обработка исключительно той информации, которая отвечает целям обработки;
                </li>
                <li>
                  содержание и объем информации соответствуют заявленным целям обработки, не допускается избыточность информации по отношению к заявленным целям обработки;
                </li>
                <li>
                  обеспечение точности информации, достаточности и в необходимых случаях актуальности по отношению к целям обработки;
                </li>
                <li>
                  хранение информации в форме, позволяющей определить Пользователя не дольше, чем этого требуют цели их обработки, если срок хранения не установлен федеральным законом, договором, стороной которого, выгодоприобретателем по которому является Пользователь, уничтожение информации, по достижении целей их обработки, если иное не предусмотрено законодательством Российской Федерации.
                </li>
              </ul>
            </p>
            <p>
              4.2. Сбор и получение Информации
            </p>
            <p>
              Сбор персональных данных и иной пользовательской информации осуществляется в Сервисах при регистрации и/или авторизации Пользователя, оформления Заказа путем заполнения соответствующих форм или иными доступными способами, в том числе
              с использованием инструментария Сервисов, а также в дальнейшем при редактировании и дополнении Пользователем ранее предоставленной информации в рамках заключенного соглашения между Пользователем и Обществом.
            </p>
            <p>
              Сбор иных пользовательских данных осуществляется Обществом самостоятельно
              в процессе использования Пользователем Сервисов. В ряде случаев сбор иных пользовательских данных Пользователя начинается с момента получения Пользователем доступа к Сервису (например, при загрузке интернет-страницы). Данная информация, собирается Обществом с согласия Пользователя, предоставляемого в форме конклюдентного действия при первом запуске и/или использовании Пользователем Сервисов (в частности, при нажатии соответствующей кнопки при первом запуске и/или использовании Сервисов).
            </p>
            <p>4.3. Обработка Сookies. Использование метрических программ</p>
            <p>
              Сookies представляют собой небольшие фрагменты данных, которые сервисы запрашивают у браузера, используемого на устройстве Пользователя. Хранятся Сookies локально на устройстве Пользователя. Общество обрабатывает Сookies, собранные в процессе посещения и использования Пользователем Сервисов.
            </p>
            <p>
              Файлы Сookies, используемые Сервисами, можно разделить на следующие категории:
              <ul>
                <li>
                  сессионные Сookies, временные файлы Сookies, существующие во временной памяти, пока Пользователь использует Сервисы. Браузеры обычно удаляют сессионные Сookies после того, как Пользователь закрывает окно браузера;
                </li>
                <li>
                  постоянные Сookies удаляются в определённую дату или через определённый промежуток времени, они остаются на устройстве Пользователя и после закрытия браузера;
                </li>
                <li>
                  обязательные, минимальный набор Сookies, использование которых необходимо для корректной работы Сервисов.
                </li>
              </ul>
            </p>
            <p>
              Сервисы используют файлы Сookies c целью обеспечения полноценного функционирования Сервисов, в том числе, улучшения функционала, производительности и эффективности Сервисов, аналитики использования Сервисов.
            </p>
            <p>
              В вышеуказанных целях Информация об использовании Сервисов, собранная в том числе с помощью файлов Сookies, может быть передана сервису веб-аналитики Яндекс.Метрика. Пользователь может дополнительно ознакомиться с условиями использования и политикой конфиденциальности сервиса Яндекс.Метрика в сети Интернет по адресу:
              <ul>
                <li>
                  <a href="https://yandex.ru/legal/metrica_termsofuse" target="_blank">https://yandex.ru/legal/metrica_termsofuse</a>
                </li>
                <li>
                  <a href="https://yandex.ru/support/metrika/code/data-collected.html" target="_blank">https://yandex.ru/support/metrika/code/data-collected.html</a>
                </li>
              </ul>
            </p>
            <p>
              Пользователь может ограничить обработку данных сервисом веб-аналитики
              в процессе использования Сервисов, загрузив и установив плагин для браузера, доступный в сети Интернет по адресу: <a href="https://yandex.ru/support/metrica/general/opt-out.html" target="_blank">https://yandex.ru/support/metrica/general/opt-out.html</a>
            </p>
            <p>
              Пользователь может отказаться от обработки Сookies в настройках своего браузера. В таком случае Сервис будет использовать только обязательные Сookies.
            </p>

            <p>
              4.4. Обработка и Передача Информации
            </p>
            <p>
              Информация о Пользователе не передается третьим лицам, за исключением случаев, прямо предусмотренных соглашением с Пользователем или законодательством Российской Федерации. К таким случаям могут относиться:
              <ul>
                <li>
                  передача Информации необходима для исполнения требований законодательства, для осуществления и выполнения возложенных законодательством на Общество функций, полномочий и обязанностей;
                </li>
                <li>
                  передача Информации в целях исполнения договорных отношений между Обществом и Пользователем, предусматривающих подобное предоставление Информации, либо с согласия Пользователя, при этом обязательным условием предоставления Информации третьему лицу является обязанность сторон по соблюдению конфиденциальности и обеспечению безопасности Информации.
                </li>
              </ul>
            </p>
            <p>
              4.5. Способы обработки
            </p>
            <p>
              В Обществе осуществляется смешанная обработка Информации, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (предоставление, доступ), блокирование, удаление, уничтожение.
            </p>
            <p>
              Обработка Информации осуществляется с соблюдением требований конфиденциальности, предусмотренных законодательством, в том числе Федеральным законом «О персональных данных».
            </p>
            <p>
              4.6. Хранение Информации
            </p>
            <p>
              При хранении Информации о Пользователях Общество использует базы данных, находящиеся на территории Российской Федерации.
            </p>
            <p>
              Хранение осуществляется преимущественно на электронных материальных носителях, а обработка с использованием автоматизированных систем, за исключением случаев, когда неавтоматизированная обработка Информации о Пользователе необходима в связи с исполнением требований законодательства Российской Федерации. При хранении материальных носителей информации соблюдаются условия, обеспечивающие сохранность информации и исключающие несанкционированный к ней доступ.
            </p>
            <p>
              Хранение Информации о Пользователе осуществляется в течение срока действия соглашения между Пользователем и Обществом, а после прекращения действия такого соглашения – в течение необходимого и установленного действующим законодательством Российской Федерации срока.
            </p>
            <p>
              4.7. Срок обработки и порядок уничтожения Информации
            </p>
            <p>
              Обработка Информации прекращается при достижении целей такой обработки,
              а также по истечении срока, предусмотренного законом, договором, или согласием Пользователя на обработку его данных.
            </p>
            <p>
              При достижении целей обработки и отсутствии законных оснований для продолжения обработки Общество прекращает обработку Информации одним из способов, предусмотренных Федеральным законом «О персональных данных».
            </p>
            <p>
              Уничтожение персональных данных, содержащихся на материальных носителях,
              в том числе бумажных, осуществляется путем измельчения в бумагорезательной машине (шредирование) или путем физического уничтожения (разрушение, деформирование, сжигание) носителей информации. Уничтожение персональных данных
              в информационных системах проводится средствами встроенных функций информационных систем персональных данных или стирания файлов с использованием программ (устройств) гарантированного уничтожения информации.
            </p>
          </div>
        </section>
        <section className="content-section">
          <em>Права и обязанности</em>
          <div className="box">
            <p>
              <strong>Пользователь вправе:</strong>
              <ul>
                <li>
                  получать от Общества информацию, касающуюся обработки Информации о Пользователе в порядке, установленном законодательством, в том числе законодательством в области персональных данных;
                </li>
                <li>
                  требовать от Общества уточнения Информации, ее блокирования или уничтожения в случае, если такая информация является неполной, устаревшей, недостоверной, необоснованно полученной или не является необходимой для заявленной цели обработки;
                </li>
                <li>
                  обратиться к Обществу с отзывом согласия на обработку персональных данных или с требованием о прекращении обработки персональных данных в порядке, установленном законодательством о персональных данных;
                </li>
                <li>
                  принимать предусмотренные законом меры по защите своих прав и законных интересов.
                </li>
              </ul>
              <strong>Пользователь обязан:</strong>
              <ul>
                <li>
                  предоставлять Обществу полную и достоверную информацию о себе, необходимую для цели обработки;
                </li>
                <li>
                  сообщать Обществу об уточнении (обновлении, изменении) информации.
                </li>
              </ul>
              <strong>Общество вправе:</strong>
              <ul>
                <li>
                  ограничивать право Пользователя на доступ к Информации или отказывать
                  в предоставлении Информации в случаях, предусмотренных законодательством;
                </li>
                <li>
                  обрабатывать Информацию без согласия Пользователя в случаях, предусмотренных законодательством.
                </li>
              </ul>
              <strong>Общество обязано:</strong>
              <ul>
                <li>
                  обрабатывать Информацию в порядке, установленном Федеральным законом
                  «О персональных данных», иными нормативными правовыми актами, внутренними нормативными документами исключительно в обозначенных целях;
                </li>
                <li>
                  соблюдать конфиденциальность Информации, не раскрывать третьим лицам
                  и не распространять Информацию без согласия Пользователя, если иное
                  не предусмотрено федеральным законом;
                </li>
                <li>
                  обеспечивать защиту Информации в соответствии с требованиями законодательства Российской Федерации;
                </li>
                <li>
                  рассматривать обращения Пользователя по вопросам обработки Информации.
                </li>
              </ul>
            </p>
          </div>
        </section>
        <section className="content-section">
          <em>Меры по защите информации</em>
          <div className="box">
            <p>
              Защита от неправомерных действий. Общество принимает технические и организационно-правовые меры в целях обеспечения защиты Информации о Пользователе
              от неправомерного или случайного доступа к ней, уничтожения, изменения, блокирования, копирования, предоставления, распространения, а также от иных неправомерных действий.
            </p>
            <p>
              Конфиденциальность. Общество не раскрывает третьим лицам и не распространяет персональные и иные пользовательские данные без согласия Пользователя, если иное не предусмотрено федеральным законом.
            </p>
            <p>
              Основные меры по защите информации, принимаемые в Обществе:
              <ul>
                <li>
                  назначено лицо, ответственное за организацию обработки персональных данных,
                  и лицо, ответственное за обеспечение безопасности персональных данных;
                </li>
                <li>
                  обеспечивается выполнение требований по защите информации;
                </li>
                <li>
                  разработаны и утверждены локальные акты по вопросам обработки и защиты персональных данных, информационной безопасности;
                </li>
                <li>
                  применяется комплекс правовых, организационных и технических мер
                  по обеспечению безопасности персональных данных в соответствии с требованиями действующего законодательства в области персональных данных, информационной безопасности;
                </li>
                <li>
                  осуществляется внутренний контроль и аудит соответствия обработки персональных данных требованиям действующего законодательства в области персональных данных, информационной безопасности и локальных актов Общества;
                </li>
                <li>
                  осуществляется оценка вреда, который может быть причинен субъектам персональных данных в случае нарушения действующего законодательства в области персональных данных;
                </li>
                <li>
                  работники Общества, осуществляющие обработку персональных данных, ознакомлены с положениями законодательства о персональных данных и локальными актами Общества в области персональных данных.
                </li>
              </ul>
            </p>
          </div>
        </section>
        <section className="content-section">
          <em>Обращения Пользователей</em>
          <div className="box">
            <p>
              Сведения об обрабатываемой Обществом Информации о Пользователе, в том числе персональных данных, в связи с использованием Пользователем Сервисов, предоставляются Пользователю или его представителю при обращении или запросе. Запрос на получение информации должен соответствовать требованиям действующего законодательства и содержать:
              <ul>
                <li>
                  номер основного документа, удостоверяющего личность Пользователя или его представителя;
                </li>
                <li>
                  сведения о дате выдачи указанного документа и выдавшем его органе;
                </li>
                <li>
                  сведения, подтверждающие участие Пользователя в Сервисах (в частности сведения о регистрации в Сервисе, соответствующих идентификаторах);
                </li>
                <li>
                  подпись Пользователя или его представителя.
                </li>
              </ul>
            </p>
            <p>
              Запросы направляются в письменной форме по адресу Общества, или в форме электронного документа, подписанного квалифицированной электронной подписью
              в соответствии с законодательством Российской Федерации, по адресу электронной почты info@barashek.ru.
            </p>
            <p>
              Пользователь вправе отозвать согласие на обработку персональных данных или направить требование о прекращении обработки персональных данных путем направления Обществу письменного заявления в соответствии с требованиями действующего законодательства.
            </p>
            <p>
              С регламентом обработки запросов Пользователей по вопросам персональных данных и типовыми формами обращений Пользователей можно ознакомиться
              в Положении об обработке персональных данных ООО «Вкусные технологии», доступном
              по адресу: <a href="https://delivery.barashek.ru/agreements" target="_blank">https://delivery.barashek.ru/agreements</a>.
            </p>
          </div>
        </section>
        <section className="content-section">
          <em>Изменения Политики</em>
          <div className="box">
            <p>
              Настоящая Политика может быть изменена Обществом с уведомлением Пользователя, в том числе путем размещения новой редакции изменяемой политики на Сервисах. Изменения в Политике, внесенные Обществом, вступают в силу в день, следующий за днем размещения на Сервисах новой редакции Политики. Пользователь обязуется самостоятельно проверять настоящую Политику на предмет внесенных изменений. Неосуществление Пользователем действий по ознакомлению не может служить основанием для неисполнения Пользователем своих обязательств и несоблюдения Пользователем ограничений, установленных настоящей Политикой.
            </p>
            <p>
              Пользователь вправе отказаться от принятия изменений и дополнений в настоящую Политику, что означает отказ Пользователя от использования Сервисов и всех предоставленных ему ранее прав.
            </p>
          </div>
        </section>
        <section className="content-section">
          <em>Обратная связь</em>
          <div className="box">
            <p>
              Настоящая Политика размещена в сети Интернет по адресу: <a href="https://delivery.barashek.ru/agreements" target="_blank">https://delivery.barashek.ru/agreements</a>.
            </p>
            <p>
              Все предложения или вопросы по поводу настоящей Политики Пользователь вправе направлять в адрес ООО «Вкусные технологии» по электронной почте info@barashek.ru или по адресу: 424038, Республика Марий Эл, г. Йошкар-Ола, ул. Кирова, д. 3А, оф. 101.
            </p>
          </div>
        </section>

        <section className="content-section">
          <div className="box">
            <p>Приложение № 1. Информация о Пользователе, которая обрабатывается в Обществе</p>
            <table className='table table-bordered'>
              <thead>
                <tr>
                  <th>Информация о Пользователе</th>
                  <th width="20%">Цель обработки</th>
                  <th width="20%">Правовое основание</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colspan="3" align="center"><strong>Информация, которую Пользователь предоставляет о себе самостоятельно или через третьих лиц</strong></td>
                </tr>
                <tr>
                  <td>
                    <strong>Информация, необходимая для самостоятельной регистрации Пользователя в Сервисах</strong><br/>
                    К обрабатываемой информации при регистрации может относиться: имя, номер телефона.
                  </td>
                  <td>Исполнение обязательств по договорным отношениям с Пользователем</td>
                  <td>Договор между Обществом и Пользователем (Пользовательское соглашение)</td>
                </tr>
                <tr>
                  <td>
                    <strong>Информация, предоставляемая Пользователем при заполнении форм на Сервисах (оформлении Заказа)</strong><br/>
                    К предоставляемой информации может относиться: имя, номер телефона, адрес доставки
                  </td>
                  <td>Исполнение обязательств по договорным отношениям с Пользователем</td>
                  <td>Договор между Обществом и Пользователем (Пользовательское соглашение)</td>
                </tr>
                <tr>
                  <td colspan="3" align="center"><strong>Информация, получаемая в результате действий Пользователя при использовании Сервисов</strong></td>
                </tr>
                <tr>
                  <td rowspan="2">
                    Данные о технических средствах, технологическом взаимодействии с Сервисами (в т. ч. ip-адрес, информация о запрошенном ресурсе, время, тип и информация о программе, вид операционной системы пользователя, адрес ресурса, с которого был осуществлен переход на запрошенный ресурс).
                    Данные, собираемые посредством метрических программ.
                    Данные, получаемые с использованием Сookies
                  </td>
                  <td>Исполнение обязательств по договорным отношениям с Пользователем</td>
                  <td>Договор между Обществом и Пользователем (Пользовательское соглашение)
                    Выполнение Обществом обязанностей, возложенных законодательством
                  </td>
                </tr>
                <tr>
                  <td>Обеспечение полноценного функционирования, улучшение работы Сервисов (в том числе сайтов). Проведение аналитических исследований</td>
                  <td>Согласие на обработку</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Agreements;
