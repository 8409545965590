import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Context } from '../../context/providers';

const Payment = () => {
  const title = 'Оплата и доставка';
  const { settings } = useContext(Context);

  const defaultState = {
    shipmentThreshold: null,
    shipmentCost: '250',
  };

  const [state, setState] = useState({
    ...defaultState,
  });

  useEffect(() => {
    if (settings && (settings.shipment_threshold || settings.shipment_cost)) {
      setState({
        ...state,
        shipmentThreshold: settings.shipment_threshold,
        shipmentCost: settings.shipment_cost,
      });
    }
  }, [settings]);

  return (
    <div className="container">
      <Helmet>
        <title>{`${title} – BARAШEK`}</title>
        <meta content={`${title} – BARAШEK`} name="title" />
        <meta content={title} property="og:title" />
      </Helmet>
      <h1 className="h-tac">Оплата и Доставка</h1>
      <div className="content-index">
        <section className="content-section">
          <strong>
            Доставка и оплата: мы сделали все, чтобы вам было комфортно!
          </strong>
          <div className="box">
            Мы позаботились о том, чтобы вы могли заказывать вкусные блюда с
            максимальным комфортом! Наша команда обеспечила своим клиентам
            удобные варианты оформления, оплаты, доставки заказов и даже отказа
            от них. Но давайте обо всем поподробнее.
          </div>
        </section>
        <section className="content-section">
          <strong>Варианты оплаты</strong>
          <div className="box">
            <ul>
              <li>
                <strong>Банковской картой.&nbsp;</strong>Вы делаете заказ,
                выбирая нужные блюда, и оплачиваете его со своей банковской
                карты прямо на сайте BARAШЕК.RU. Все личные данные наших
                покупателей остаются в гарантированной неприкосновенности —
                безопасность и надежность платежей обеспечиваются сервисом
                PayAnyWay. По всем вопросам, связанным с платежами, можно
                обращаться в специальную службу поддержки PayAnyWay по телефону
                в Йошкар-Оле: +7 (8362) 45-43-83. Получить подробную информацию
                о своем заказе можно по телефону +7 (8362) 36-99-99.
              </li>
              <li>
                <strong>Картой или наличными курьеру</strong>.
              </li>
            </ul>
          </div>
        </section>
        <section className="content-section">
          <strong>Особенности доставки</strong>
          <div className="box">
            Доставка осуществляется ежедневно курьером по указанному в заявке
            адресу.
          </div>
          <div className="box">
            Доставка осуществляется до квартиры, офиса, места нахождения
            заказчика в здании назначения. Если могут возникнуть какие-то
            сложности в поиске офиса, на проходной, при входе в подъезд, на
            территорию и т.д., просьба сообщать об этом в комментарии к заказу.
          </div>
          <div className="box">
            При выборе времени доставки «ближайшее время», доставка будет
            осуществлена в минимально возможное время, в зависимости от времени
            приготовления заказа и логистических возможностей. Доставку по всему
            городу Йошкар-Ола мы стараемся выполнять в течение 1 часа. За
            пределы города доставка по запросу.
          </div>
          <div className="box">
            Если Вы хотите сделать заказ на определенное время, позднее, чем
            через час, можно выбрать определенный слот, если все заказы на это
            время не заняты. Занятые временные слоты будут неактивны. Вы можете
            заказать доставку блюд к удобному времени, например, ужин привезут к
            вашему возвращению домой.
          </div>
          {state.shipmentThreshold && (
              <div className="box">
                Для заказов на сумму свыше {state.shipmentThreshold}&nbsp;рублей
                доставка бесплатна.
                <br />
                Стоимость доставки заказов стоимостью менее{' '}
                {state.shipmentThreshold}&nbsp;рублей составляет&nbsp;
                {state.shipmentCost}&nbsp;рублей.
              </div>
          )}
          {!state.shipmentThreshold && (
              <div className="box">
                Стоимость доставки заказов составляет&nbsp;{state.shipmentCost}&nbsp;рублей.
              </div>
          )}
        </section>
        <section className="content-section">
          <strong>Возврат</strong>
          <div className="box">
            Мы предусмотрели для наших клиентов возможность возврата оплаты. Вы
            можете отказаться от своего заказа в любой момент — без всяких
            штрафных санкций с нашей стороны!
          </div>
          <div className="box">
            Для покупателей, которые решили отказаться от заказанных блюд,
            BARAШЕК обеспечивает максимально комфортные варианты возврата всей
            суммы.
          </div>
          <div className="box">
            <ul>
              <li>
                В случае если отказ не связан с качеством продукции —
                выплаченные за него деньги будут возвращены на внутренний счет
                или компенсированы сертификатом на идентичную сумму.
              </li>
              <li>
                В случае если отказ мотивирован ненадлежащим качеством — полная
                сумма оплаты за него будет возвращена на банковскую карту
                клиента или внутренний счет сайта покупок, с которого
                производилась оплата.
              </li>
            </ul>
          </div>
          <div className="box">
            Вряд ли эта информация вам пригодится, но вы можете быть уверены, мы
            на вашей стороне!
          </div>
        </section>
        <section className="content-section">
          <strong>Гарантия безопасности</strong>
          <div className="box">
            Сервис PayAnyWay соответствует международному стандарту PCI DSS
            (Payment Card Industry Data Security Standard, PCI DSS) версии 2.0
            Level 1. Инфраструктура сервиса прошла необходимые испытания,
            включающие в себя сканирование уязвимостей, тестирование на
            проникновение и сертификационный QSA-аудит. Результаты испытаний
            подтвердили высокий уровень безопасности системы и процессов,
            связанных с платёжными данными наших клиентов. Для защиты платежей
            со счетов банковских карт используются технологии 3D-Secure от VISA
            и SecureCode от MasterCard.
          </div>
          <div className="box">
            В случае, если у Вас есть вопросы по совершенному платежу, вы можете
            обратиться в службу поддержки клиентов по телефону в Йошкар-Оле: +7
            (8362) 45-43-83
          </div>
        </section>
      </div>
    </div>
  );
};

export default Payment;
